@import 'scss/_mixins.scss';
@import 'scss/_breakpoints.scss';

.fdic-container {
	--gray-color: #eaeaea;

	z-index: 3;
	padding-top: 15px;
	border-top: 1px solid var(--gray-color);
}

.fdic-container--desktop {
	display: block;
}

.fdic-container--mobile {
	display: none;
}

.fdic-logo--desktop {
	max-width: 600px;
	height: auto;
}

.fdic-logo--mobile {
	max-width: 375px;
	height: auto;
}

@include breakpoint( md ) {
	.fdic-container--desktop {
		display: none;
	}

	.fdic-container--mobile {
		display: block;
	}
}
