@import '../../design-system/src/scss/breakpoints';

.masthead {
  //background-color: var(--body-background-color);
  position: relative;
  z-index:12;
  &--simple {
    margin-bottom: 100px;
  }

  .util-nav-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .logo {
    width: 110px;
    position: relative;
    z-index: 30;
  }

  .header-container {
    position: relative;
    top: 51px;
  }
  .cta {
    position: relative;
    width: auto;
    max-width: fit-content;
    margin: -20px 0 0 auto;
    margin-right:calc(var(--spacer-6)/2);
    text-align: right;
    z-index:5;
  }

  .masthead__contents {
    --container-width: calc(100% - var(--spacer-6));
    --container-max-width: 1370px;
    --container-width-wide: 1370px;
    position: relative;
    width: var(--container-width);
    max-width: var(--container-max-width);
    margin: auto;
  }
  @include breakpoint(md) {
    display: flex;
    flex-direction: column;
    height: 125px;
    .masthead__background {
      transition: all var(--animation-speed-fast) var(--animation-easing-sine-in);
      transition-delay: var(--animation-speed-fast);
      transform: translateX(100%);
      position: fixed;
      height: 100%;
      width: 100%;
      overflow: auto;
      top: 0;
      left: 0;
      z-index: 19;
    }
    .masthead__contents {
      opacity: 0;
      transition: opacity var(--animation-speed-fast);
    }
    .util-nav-container {
      display: flex;
      flex-direction: column;
      z-index: 17;
      transform: scaleY(0);
      transform-origin: top;
      overflow: hidden;
      //max-height: 0;
      transition: all var(--animation-speed-slow) var(--animation-easing-sine-out);
      position: relative;
      &:last-of-type(section) {
        transform: scaleY(1);
      }
      .ds-nav {
        display: block;
      }
      ul {
        display: block;
        flex-direction: column;
        margin: 0 8px;
        height: 100%;
        li {
          font-size: 1rem;
          padding: 10px 0;
        }
      }
    }
    .header-container {
      display: flex;
      flex-direction: column;
      margin: 0;
      position: relative;
      z-index: 15;
      top: 0;
      width: 100%;
      padding: 0 var(--spacer-4);
      header {
        overflow: hidden;
        max-height: 0;
        transition: all var(--animation-speed-fast) ease;
        position: relative;
      }
    }
    .cta {
      margin: var(--spacer-2) auto 0;
      max-height: 0;
      max-width: none;
      overflow: hidden;
      padding: 0 var(--spacer-1);
      text-align: left;
      button {
        width: 100%;
      }
    }

    &[open] {
      .masthead__background {
        background-color: #fff;
        transform: translateX(0);
        transition: all var(--animation-speed-slow) var(--animation-easing-sine-out);
        transition-delay: 0;
      }
      .masthead__contents {
        opacity: 1;
        transition-delay: 0.5s;
        --container-width: auto;
      }
      .masthead__header {
        background-color: #fff;
        z-index: 40;
      }
      height: none;

      .util-nav-container,
      .header-container header,
      .cta {
        transform: scaleY(1);
        transform-origin: top;
        overflow: visible;
        max-height: none;
        transition: all var(--animation-speed-slow) var(--animation-easing-sine-in);
        --container-width: calc(100% - var(--spacer-6));
        --container-max-width: 1370px;
        --container-width-wide: 1370px;  
        width: var(--container-width);      
      }
      .header-container header nav {
        border-bottom: solid 1px var(--input-color);
        padding-bottom: var(--spacer-3);
      }
    }
  }
}
.masthead--home {
  @include breakpoint(md) {
    height: 145px;
  }
}
